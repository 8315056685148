.footerText {
  
    border-top: solid 1px rgb(219, 219, 219);
    font-size: large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    margin: 0px auto;
    text-align: center;
    padding: 25px;
    text-align: center;
    background-color: #EEEEEE;
  }
  .footer {
   position: relative;
   left: 0;
   bottom: 0;
   height:10px;
    background-color: #eeeeee;
    border-top: solid 1px rgb(219, 219, 219);
    font-size: large;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    padding: 5px;
  }
  
  
  @media (max-width: 600px) {
    .footerText {
     margin-top: 2rem;
    }
    .footer {
      position: relative;
      left: 0;
      bottom: 0;
      height:10px;
       background-color: #eeeeee;
       border-top: solid 1px rgb(219, 219, 219);
       font-size: large;
       font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
       text-align: center;
       padding: 5px;
     }
  }
  