/* Sample CSS for styling the component */
.how-it-works-container {
    background-color: #f4f4f4;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .section-heading {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .section-content {
    display: grid;
    grid-gap: 20px;
  }
  
  .step {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .step h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .step p {
    font-size: 16px;
    color: #666;
  }
  