/* .pageContainer {
 background-color: white;
} */

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 60px;
  /* background-color: white; */
  border-bottom: 1px solid rgb(230, 230, 230);
}
.logo {
  width: 70px;
  height: 70px;
}

.login {

    min-width: 300px;
    min-height: 60px;
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 700;
    color: white;
    background: #ef7f1a;
  background: linear-gradient(90deg, #ef7f1a 0%, #ef7f1a 100%);
    border: none;
    border-radius: 1000px;
    box-shadow: 12px 12px 24px #f3a663;
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 10px;
    margin-left: 12rem;
    margin-bottom: 6rem;
    }
  
  .login::before {
  content: '';
    border-radius: 1000px;
    min-width: calc(300px + 12px);
    min-height: calc(60px + 12px);
    border: 6px solid #ef7f1a;
    box-shadow: 0 0 60px #f3a663;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
  }
  
  .login:hover, .login:focus {
    color: #ffffff;
    transform: translateY(-6px);
  }
  
  .login:hover::before, .login:focus::before {
    opacity: 1;
  }
  
  .login::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 6px solid  #ef7f1a;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
  }
  
  
  
  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }


.hero {
  width: 50%;
  margin: 30px auto;
  margin-top: 70px;
  line-height: 80px;
  min-height: 420px;
}


.hero h1 {
  font-family: "Spectral SC";
  font-weight: 400;
  font-size: 70px;
  line-height: 70px;
  text-align: center;
  margin-bottom: 5rem;
}

.paragraph {
  margin-top: 20px;
  text-align: center;
  line-height: 20px;
  color: gray;
}

.imgContainer {
  margin-top: -70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero img {
  width: 1200px;
  margin: 0px auto;
}


.footerText {
  border-top: solid 1px rgb(219, 219, 219);
  width: 80%;
  margin: 0px auto;
  text-align: center;
  padding: 25px;
  text-align: center;
}



/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .hero {
    width: 80%;
    min-height:640px;
  }

  .hero h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .hero img {
    width: 100%;
    max-width: 400px;
    margin: 70px auto;
  }

  .wrap {
    display: flex;
    justify-content: center;
  }

  .login {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
