/* phoneInputStyles.css */

/* Base PhoneInput styles */
.PhoneInput {
    width: 100%;
    height: 40px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* PhoneInput input field styles */
  .PhoneInput input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 8px;
    font-size: 16px;
  }
  
  /* PhoneInput error styles */
  .PhoneInput.error {
    border-color: #ff0000; /* Example error border color */
  }
  
  /* PhoneInput focus styles */
  .PhoneInput:focus-within {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1); /* Example focus box shadow */
  }
  
  /* PhoneInput disabled styles */
  .PhoneInput:disabled {
    background-color: #f0f0f0; /* Example disabled background color */
    color: #999; /* Example disabled text color */
  }
  