/* Settings.module.css */

.pageContainer {
  background-color: #eeeeee;
  min-height: 50vh;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.hero {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.hero h1 {
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 1.2;
}

.paragraph {
  margin-top: 20px;
  text-align: center;
  color: gray;
}

.imgContainer {
  margin-top: -80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for listItem and autoGrid */
.autoGrid {
  --auto-grid-min-size: 16rem;
  width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 0.5rem;
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 160px;
  padding: 1rem;
  list-style-type: none;
  text-align: center;
  border-bottom: 1px solid #b8b8b8;
  border-radius: 4px;
  background-color: white;
}

.listItem h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  cursor: pointer;
}

.listItem p {
  font-size: 2.5rem;
  line-height: 45px;
  padding: 0px 25px 10px 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  cursor: pointer;
  color: #000;
}

.listItem:hover {
  background-color: #f8f8f8;
}

.listItem:hover h2 {
  color: #000;
}

.imgThumbnail {
  margin: 0 auto;
  border-radius: 50%;
  object-fit: cover;
  width: 25px;
  height: 25px;
}

.imgThumbnail:hover {
  filter: grayscale(100%);
  cursor: pointer;
}

/* Media queries */
@media all and (max-width: 768px) {
  .pageContainer {
    min-height: 740px;
  }

  .items {
    width: 100%;
  }

  .imgThumbnail {
    width: 20px;
    height: 20px;
  }

  .hero {
    width: 100%;
  }
}
