.AddIcon {
  width: 2rem;
  height: 2rem; /* Adjust the height as per your preference */
}

.dialogPaper {
  min-width: 300px;
}

.meetings-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin: 5px 6rem;


  position: relative; /* Add this line */
}

.centered-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .table {
  margin-left: 90px;
} */

.table-container {
  overflow-x: auto;
}

.table-responsive {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .table-responsive {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  .table {
    margin-left: 0;
  }
  .root {
    min-height: 680px;
  }
}

.root {
  min-height: 480px;
}
